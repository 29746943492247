.ant-table-thead > tr > th {
  font-size: 1.125rem;
}

@media screen and (max-width: 460px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    font-size: 1rem;
    padding: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-table {
    line-height: 0.875rem;
  }
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@media screen and (min-width: 460px) {
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    font-size: 1.125rem;
    padding: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-table {
    line-height: 2rem;
  }
}
.ant-input {
  padding-left: 1.25rem;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-breadcrumb-link {
  cursor: pointer;
}

.ant-pagination-options-quick-jumper input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.ant-pagination {
  font-size: 0.875rem;
  padding: 10px 0;
}

.ant-pagination-item a {
  padding: 0 0.375rem;
}

.ant-empty {
  margin: 1rem 0.5rem;
  font-size: 0.875rem;
}

.ant-table-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #dddddd;
}

.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0px;
  background-color: #ffffff;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ececec;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #d4d4d4;
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}

.cdk-global-scrollblock {
  overflow: hidden;
}

.ant-descriptions-item {
  display: flex;
  align-items: center;
}

.ant-descriptions-item-label {
  color: #8184a1;
}

.ant-descriptions-item-content {
  color: #070c34;
}

@media screen and (min-width: 500px) {
  .ant-descriptions-item {
    padding: 1rem 0 !important;
  }
  .ant-descriptions-item-label {
    width: 10rem;
    font-size: 1.25rem;
    margin-right: 2rem;
  }
  .ant-descriptions-item-content {
    font-size: 1.25rem;
    max-height: 20rem;
    overflow: auto;
  }
}
@media screen and (max-width: 500px) {
  .ant-descriptions-item {
    font-size: 1rem;
    padding: 10px 0 !important;
  }
  .ant-descriptions-item-label {
    width: 80px;
  }
  .ant-descriptions-item-content {
    max-height: 20rem;
    overflow: auto;
  }
}
@media screen and (min-width: 500px) {
  .ant-btn-lg {
    border-radius: 0.2rem;
  }
}
@media screen and (max-width: 500px) {
  .ant-btn-lg {
    font-size: 1rem;
  }
}
.ant-btn-lg {
  height: 2.5rem;
  min-width: 6rem;
}

.ant-btn {
  padding: 0 1rem;
}

.ant-tooltip-inner {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.32);
  width: auto;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: white;
}

body,
html {
  background-color: #f5f6fc;
  color: #060a33;
  overflow: hidden;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  height: 100vh;
}

div {
  box-sizing: border-box;
}

@media screen and (max-width: 460px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 460px) and (max-width: 780px) {
  html {
    font-size: 13px;
  }
}
@media screen and (min-width: 780px) and (max-width: 1100px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1400px) {
  html {
    font-size: 15px;
  }
}
@media screen and (min-width: 1400px) {
  html {
    font-size: 16px;
  }
}
._box-shadow {
  box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
}

._box-shadow-hover:hover {
  box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
}

._border {
  border: 1px solid #eff1fb;
}

._border-y {
  border-top: 1px solid #eff1fb;
  border-bottom: 1px solid #eff1fb;
}

@media screen and (min-width: 460px) {
  ._box-shadow-lg {
    box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
  }
  ._border-lg {
    border: 1px solid #eff1fb;
  }
}
@media screen and (max-width: 460px) {
  ._box-shadow-sm {
    box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
  }
  ._border-sm {
    border: 1px solid #eff1fb;
  }
  ._border-y-sm {
    border-top: 1px solid #eff1fb;
    border-bottom: 1px solid #eff1fb;
  }
}