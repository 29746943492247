body,
html {
  background-color: #f5f6fc;
  color: #060a33;
  overflow: hidden;

  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
body {
  height: 100vh;
}
div {
  box-sizing: border-box;
}
//修改滚动条样式

//低于460px 变手机模式
@media screen and (max-width: 460px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 460px) and (max-width: 780px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 780px) and (max-width: 1100px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 1400px) {
  html {
    font-size: 16px;
  }
}
