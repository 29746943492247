._box-shadow {
  box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
}
._box-shadow-hover:hover {
  box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
}

._border {
  border: 1px solid #eff1fb;
}
._border-y {
  border-top: 1px solid #eff1fb;
  border-bottom: 1px solid #eff1fb;
}

@media screen and (min-width: 460px) {
  ._box-shadow-lg {
    box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
  }
  ._border-lg {
    border: 1px solid #eff1fb;
  }
}
@media screen and (max-width: 460px) {
  ._box-shadow-sm {
    box-shadow: 0px 1px 4px 0px rgba(94, 48, 173, 0.06);
  }
  ._border-sm {
    border: 1px solid #eff1fb;
  }
  ._border-y-sm {
    border-top: 1px solid #eff1fb;
    border-bottom: 1px solid #eff1fb;
  }
}
